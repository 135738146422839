var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("e-file-uploader", {
        attrs: { single: false, "cat-list": _vm.catList, "file-ext": "jpg" },
        on: { completed: _vm.uploadCompleted1 },
        model: {
          value: _vm.fileList1,
          callback: function ($$v) {
            _vm.fileList1 = $$v
          },
          expression: "fileList1",
        },
      }),
      _vm._v(" " + _vm._s(_vm.fileList1) + " "),
      _c("hr"),
      _c("e-file-uploader", {
        attrs: {
          single: true,
          "cat-list": _vm.catList,
          "file-ext": "jpg,txt",
          "file-size": "10MB",
        },
        on: { completed: _vm.uploadCompleted2 },
        model: {
          value: _vm.fileList2,
          callback: function ($$v) {
            _vm.fileList2 = $$v
          },
          expression: "fileList2",
        },
      }),
      _vm._v(" " + _vm._s(_vm.fileList2) + " "),
      _c("hr"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }